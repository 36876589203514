/* Fonts */
/* Fonts */
/* @import "./assets/scss/lato.css";
@import "./assets/scss/ubuntu.css"; */

.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;
}
.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  margin-top: 80px;
  margin-left: 120px;
  z-index: 2;
}

body .MuiTooltip-tooltip.wielo-tooltip {
  background-color: #fff !important;
  margin: 0;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px;
}
body,
html,
#root {
  height: 100%;
  width: 100%;
}

html {
  /* scroll-behavior: smooth;
  -webkit-overflowscrolling: "touch"; */
  overflow-y: hidden;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  html,
  body {
    width: 100%;
    height: 100%;
  }
}

html {
  padding: 0;
  margin: 0;
  /* overflow-y: scroll !important; */
}

body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body > #triangulr-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  opacity: 0.08;
}

.wordcloud {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.wordcloud svg {
  margin: 1rem 0;
  cursor: pointer;
}

.wordcloud label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-right: 8px;
}
.wordcloud textarea {
  min-height: 100px;
}

/* .svg-BG {
  position: absolute;
  opacity: 0.1;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
